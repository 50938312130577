import axios from "axios"
import { Business } from "@/api/interface/business"
import { Media } from "../interface/media"

export const getBusinesses = (params = {}): Promise<Array<Business>> =>
  axios.get(`v2/businesses`, { params }).then((res) => res.data.data)

export const getBusiness = (
  businessId: Business["id"],
  params = {}
): Promise<Business> =>
  axios
    .get(`v2/businesses/${businessId}`, { params })
    .then(async (res) => { 
      // TODO remove this mess as soon as Stripe support is deployed to prod
      // at that point, business media will always be returned with business call
      const data = res.data.data
      if (!data.media) {
        try {
          const medias = await getBusinessMedias(businessId);
          data.media = medias
        } catch (e) {
          console.error('error', e)
        }
      }
      return data
  })

export const updateBusiness = (
  business: Partial<Business>
): Promise<Business> =>
  axios
    .patch(`v2/businesses/${business.id}`, business)
    .then((res) => res.data.data)

export const createBusinessMedia = (
      businessId: Business["id"],
      media: FormData,
  ): Promise<Media> => 
    axios
      .post(`v2/businesses/${businessId}/media`, media)
      .then((res) => res.data.data)

export const getBusinessMedias = (
  businessId: Business["id"]
) => 
  axios
    .get(`v2/businesses/${businessId}/media`)
    .then(res => res.data.data)


export const getPublicBusiness = (
  businessId: Business["id"],
) =>
  axios
    .get(`v2/public/businesses/${businessId}`)
    .then(res => res.data.data)

export const deleteBusiness = (businessId: Business['id']) => {
  return axios
    .delete(`v2/businesses/${businessId}/trash`)
    .then(res => res.data.data)
}