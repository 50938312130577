import React, {
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  forwardRef,
} from 'react'
import { styled } from '@/styles/theme'
import Label from '@/components/common/forms/Label'

interface Props {
  autoFocus?: boolean
  className?: string
  value: any
  id?: string
  showEmptyOption?: boolean
  label?: string
  required?: boolean
  disabled?: boolean
  style?: object
  name?: string
  options: Array<any>
  onChange: ChangeEventHandler<HTMLSelectElement>
  onBlur?: FocusEventHandler<HTMLSelectElement>
}

const StyledSelect = styled('select', {
  color: '$slate',
  height: '$10',
  fontSize: '$body',
  paddingRight: '$8',
  marginBottom: '$1',
  lineHeight: '$10',
  appearance: 'none',
  display: 'block',
  backgroundColor: '$white',
  background: 'url(/icons/caret-down.svg) right 0.5rem center no-repeat',
  border: '$sizes$px solid $colors$border',
  paddingLeft: '$4',
  borderRadius: '$1',
  fontFamily: '$default',
  position: 'relative',
  textOverflow: 'ellipsis',
  '&:active, &:focus': {
    border: '2px solid $colors$blue',
    outline: 'none',
  },
  '&:disabled': {
    backgroundColor: '$gray-light',
    cursor: 'not-allowed',
  },
  variants: {
    borderLess: {
      true: {
        border: '0',
        '&:active, &:focus': {
          border: '0',
          outline: 'none',
        },
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
})

export default function Select({
  autoFocus,
  className,
  value,
  id,
  showEmptyOption,
  label,
  required,
  options,
  onBlur,
  ...rest
}: Props) {
  const fieldId = id || label?.toLowerCase().replace(' ', '_')
  return (
    <span className={className}>
      {label && (
        <Label htmlFor={fieldId}>{`${label}${required ? ' *' : ''}`}</Label>
      )}
      <StyledSelect
        id={fieldId}
        value={value}
        onBlur={onBlur}
        autoFocus={autoFocus}
        {...rest}
      >
        <>
          {showEmptyOption && <option value={null} />}
          {options?.map((x, i) => (
            <option
              key={i}
              value={x.value}
              disabled={x.disabled}
              selected={x.selected}
            >
              {x.label}
            </option>
          ))}
        </>
      </StyledSelect>
    </span>
  )
}
