import axios from "axios"
import { Location } from "@/api/interface/location"
import { Queue } from "@/api/interface/queue"
import { WaitTime } from "@/api/interface/wait-time"

interface Params {
  sort: string
}

export const getWaitTimesByLocationId = (
  locationId: Location["id"]
): Promise<Array<WaitTime>> =>
  axios.get(`v2/public/wait-times/${locationId}`, {
    params: { 
      sort: "index"
    } as Params
  }).then((res) => res.data.data)

export const getWaitTimesByLocationIdAndQueueId = (
  locationId: Location["id"],
  queueId: Queue["id"]
): Promise<Array<WaitTime>> =>
  axios
    .get(`v2/public/wait-times/${locationId}/${queueId}`)
    .then((res) => res.data.data)
