export const CustomFieldVisibility = {
  webapp: { label: "Staff Intake", value: "webapp" },
  self_check_in: { label: "Self Check-in", value: "self_check_in" },
  all: { label: "All", value: "all" },
} as const

export const isCustomFieldVisible = (
  cfVisibility: keyof typeof CustomFieldVisibility,
  currentVisibility: keyof typeof CustomFieldVisibility
) => {
  return (
    cfVisibility === currentVisibility ||
    cfVisibility === CustomFieldVisibility.all.value
  )
}
